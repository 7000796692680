<wlms-web-sliding-menu></wlms-web-sliding-menu>

<div class="app-l-layout-wrapper min-h-0 d-flex flex-column h-90">
  <!-- Header -->
  <div class="app-l-header__bottom">
    <div class="app-l-header__inner-wrap app-l-container">
      <div class="app-l-header__left">
        <label class="app-c-label">{{ allData?.length }} LOANS ARE READY TO BE ASSIGNED</label>
          <h3>
            HELLO,<span class="app-c-label-name">{{
              userDetails?.name
            }}</span>
          </h3>
      </div>
    </div>
  </div>

  <!-- Icon section -->
  <div class="d-flex justify-content-end mr-100">
    <div *wlmsIfPrivilege="[screenActionPrivileges.AllowTestLoanToggle]">
      <label class="app-c-label">Test Mode
        <input type="checkbox" class="app-c-label" (click)="onTestLoanChanged($event)">
      </label>
    </div>

    <wlms-warehouseline-dropdown [buttonGroup]="losList" [disableButton]="false"[buttonLabel]="losSelected?.name" (optionSelected)="onLosSelected($event)">
    </wlms-warehouseline-dropdown>
    <div *wlmsIfPrivilege="[screenActionPrivileges.Search]">
      <a class="nounderscore" title="Loan Lookup/Add Exception" (click)="loanLookUp()"
        ><i class="icon-search header-icon"></i
      ></a>
    </div>
  
    <div *wlmsIfPrivilege="[screenActionPrivileges.Refresh]">
      <a class="nounderscore" title="Refresh Loans" (click)="rereshLoans()"
        ><i class="icon-refresh header-icon"></i
      ></a>
    </div>
    <div *wlmsIfPrivilege="[screenActionPrivileges.ReProcessLoanUpdate]">
      <a  class="nounderscore" title="Reprocess Loan updates" (click)="showReprocessLoanUpdatesModal()" [class.disabled]="disableReProcessUpdates"
        ><i class="icon-pipeline header-icon"></i
      ></a>
    </div>
    <div *wlmsIfPrivilege="[screenActionPrivileges.LoanSavePreference]">
      <a
      class="nounderscore"
        title="Save Preference"
        [class.disabled]="!loanAssignmentGrid.isUserPreferenceChanged()"
        (click)="showUserPreferenceModal()"
      >
        <i class="icon-save header-icon"></i>
      </a>
    </div>
    <div *wlmsIfPrivilege="[screenActionPrivileges.LoanSavePreference]">
      <a
      class="nounderscore"
        title="Reset Preference"
      [class.disabled]="!(loanAssignmentGrid.isUserPreferenceChanged() || this.disableResetPreference)"
        (click)="showResetUserPreferenceModal()"
      >
        <i class="icon-reset header-icon"></i>
      </a>
    </div>
  </div>

  <div class="app-l-container vertical-stack app-l-grid-action">
    <div class="app-c-grid min-h-0 d-flex flex-column h-100 mt-1">
      <div style="display:flex">
          <!-- Tab section -->
      <div class="col-lg-10 col-sm-9">
        <wlms-tab-set
        [isLabelDisplayed]="true"
        [tabList]="tabList"
        [selectedIndex]="warehouselineTabIndex"
      ></wlms-tab-set>
    </div>
      
    
      <div class="col-lg-2 col-sm-3">
        <wlms-warehouseline-dropdown
          [defaultButton]="null"
          [buttonGroup]="loanAssignmentActions"
          [disableButton]="false"
          [buttonLabel]="'Action'"
          (optionSelected)="onOptionSelected($event)"
        ></wlms-warehouseline-dropdown>
        <div class="selected-summary">
          {{ this.loansReviewed }} Reviewed
          <span
            *ngIf="
              tabList[selectedTab].name.toUpperCase() !=
                TabConstantAll.toUpperCase() &&
              tabList[selectedTab].name.toUpperCase() !=
                TabConstantUnassigned.toUpperCase()
            "
            >|</span
          >
          <span
            *ngIf="
              tabList[selectedTab].name.toUpperCase() !=
                TabConstantAll.toUpperCase() &&
              tabList[selectedTab].name.toUpperCase() !=
                TabConstantUnassigned.toUpperCase()
            "
            [ngClass]="checktimeZero ? 'app-c-label-cutoff' : ''"> Cutoff: {{ hoursToDday }}hr {{ minutesToDday }}min</span
          >
        </div>
      </div>
    </div>

      <!-- Tile Section -->
      <div class="d-flex flex-wrap justify-content-end" *wlmsIfPrivilege="[screenActionPrivileges.LoanQueueTile]">

        <ng-container *ngFor="let tileInfo of tileList">
          <wlms-loan-info-tile
            [tileInfo]="tileInfo"
            *ngIf="
              !isTileExcluded(tileInfo) &&
              tabList[selectedTab].name.toUpperCase() !=
                TabConstantAll.toUpperCase() &&
              tabList[selectedTab].name.toUpperCase() !=
                TabConstantUnassigned.toUpperCase()
            "
            [ngClass]="
              tileInfo?.icon || tileInfo?.remainingPercentage
                ? 'col-lg-3 p-1'
                : 'col-lg-2 p-1'
            "
            class="mb-3 mt-3 col-sm-6"
          >
          </wlms-loan-info-tile>
         
        </ng-container>
        
      </div>

      <!-- Grid section -->
      <wlms-grid
        #loanAssignmentGrid
        [rowData]="rowData"
        [isRefresh]="isRefresh"
        [columnDefs]="columnDefs"
        [paginationPageSize]="paginationPageSize"
        [noRowsOverlayFlags]="noRowsOverlayFlags"
        (rowSelectionEvent)="rowSelectionEvent($event)"
        (optionSelected)="onWarehouselineChanged($event)"
        (filterChangeEvent)="onfilterChanged($event)"
        [selectedIndices]="selectedIndices"
        [failureMoveLoansIdx]="failureMoveLoansIdx"
        [suppressRightClickPrivilege]="suppressRightClickPrivilege"
        [suppressActionPrivilege]="suppressActionPrivilege"
        (noOverlay)="noOverlay($event)"
        [isRowSelectable]="onIsRowSelectable"
        [getRowClass]="onGetRowClass"
        (cellEditStopped)="onCellEditStopped($event)"
      >
      </wlms-grid>
      
    </div>
  </div>
</div>

<!-- Sliding panel on the right -->
<wlms-view-loan-panel [openPanel]="openPanel" [selectedLoan]="selectedLoan">
</wlms-view-loan-panel>

<div class="spinneroverlay" *ngIf="showspinner">
  <div class="spinnercenter">
      <mat-progress-spinner diameter=50  mode="indeterminate"></mat-progress-spinner> 
  </div>
</div>